import _axios from "axios";
import parseCsv from "csv-parse/lib/sync";

export const host = "https://anitya-storage.oss-cn-shanghai.aliyuncs.com";

export const baseURL = `${host}/api/%40screening-room`;

export const axios = _axios.create({ baseURL });

export async function fetchCsv<T>(path: string): Promise<T[]> {
  const { data } = await axios.get(path);
  const [keys, , ...list] = parseCsv(data.replace(/.+\n/, ""));
  return list.map((item: string) =>
    Object.fromEntries(
      keys.map((key: string, index: number) => [key, item[index]])
    )
  );
}
